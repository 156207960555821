/* eslint-disable import/no-unresolved */
<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :lock-scroll="false"
    top="2rem"
    width="50%"
    class="nsi-energy-object"
    :close-on-click-modal="false"
  >
   <div slot="title">
      Устройства РЗА
      <template v-if="userCanEdit">
       <el-button v-if="!editMode" class="back_action_panel--first_element" @click="handleEdit" type="text">
         <i class="el-icon-edit"></i> Редактировать
       </el-button>
       <el-button v-if="editMode" class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
         <SaveIcon /> Сохранить
       </el-button>
       <el-button @click="handleDelete(secondEquipmentPanel.id)" :disabled="secondEquipmentPanel.isAip || secondEquipmentPanel.id == null" type="text" icon="el-icon-delete">
         Удалить
       </el-button>
      </template>
   </div>
    <el-form :rules="rules" v-loading="loading" ref="secondEquipmentPanelForm" :model="secondEquipmentPanel" label-width="18rem">
      <el-form-item prop="isAip" label="СК-11:">
        <el-checkbox
          v-model="secondEquipmentPanel.isAip"
          disabled>
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="analysisIds" label="Идентификатор ПК &quot;Анализ 2009&quot;:">
        <el-select v-model="secondEquipmentPanel.analysisIds"
                   multiple
                   filterable
                   allow-create
                   default-first-option
                   placeholder=""
                   :disabled="!editMode">
        </el-select>
      </el-form-item>
      <el-form-item prop="mRid" label="mRid:">
        <el-input
          v-model="secondEquipmentPanel.mRid"
          :disabled="!editMode || secondEquipmentPanel.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="psrTypeId" label="Группа учета РЗА:">
        <el-input
          :value="panelRegistrationGroupName"
          disabled>
        </el-input>
      </el-form-item>
      <el-form-item prop="name" label="Наименование:">
        <el-input
          v-model="secondEquipmentPanel.name"
          :disabled="!editMode || secondEquipmentPanel.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="productAssetModelId" label="Фирменное устройство РЗА:">
        <remote-search-input
            v-model="secondEquipmentPanel.productAssetModelId"
            searchUrl="/api/productAssetModels/SearchByName"
            return-prop="id"
            size="large"
            :allow-create="false"
            :show-when-init="true"
            :initial-value="secondEquipmentPanel.productAssetModelName"
            :disabled="!editMode || secondEquipmentPanel.isAip"
          />
      </el-form-item>
      <el-form-item  prop="baseVoltageId" label="Класс напряжения:">
        <el-select v-model="secondEquipmentPanel.baseVoltageId" :disabled="true"
                    placeholder="Без напряжения"
                   filterable
                   clearable>
          <el-option
            v-for="item in baseVoltages"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="energyObjectId" label="Энергообъект:">
        <div class="selected-tags">
          <el-tag
            v-if="secondEquipmentPanel.energyObjectId"
            :key="secondEquipmentPanel.energyObjectId"
            size="small"
            class="mr-1 mb-1">
            {{secondEquipmentPanel.energyObjectName}}
          </el-tag>
          <el-button v-if="editMode && !secondEquipmentPanel.isAip" type="primary" size="small" @click="handleEnergyObjectsSelect">
            Выбрать
          </el-button>
        </div>
        <!-- <remote-search-input
            v-model="secondEquipmentPanel.energyObjectId"
            searchUrl="/api/energyObjects/SearchByName"
            return-prop="id"
            size="large"
            :allow-create="false"
            :show-when-init="true"
            :initial-value="secondEquipmentPanel.energyObjectName"
            :start-length=3
            :disabled="!editMode || secondEquipmentPanel.isAip"
          /> -->
      </el-form-item>
      <el-form-item prop="startDate" label="Дата ввода в эксплуатацию:">
        <el-date-picker
          type="date"
          v-model="secondEquipmentPanel.startDate"
          v-mask="'##.##.####'"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          style="width: 40%;"
          :disabled="!editMode"
          :picker-options="datePickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="endDate" label="Дата вывода из эксплуатации:">
        <el-date-picker
          type="date"
          v-model="secondEquipmentPanel.endDate"
          v-mask="'##.##.####'"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          style="width: 40%;"
          :disabled="!editMode"
          :picker-options="datePickerOptions">
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item prop="normallyInService" label="В работе:">
        <el-checkbox
          v-model="secondEquipmentPanel.normallyInService"
            :disabled="!editMode || secondEquipmentPanel.isAip">
        </el-checkbox>
      </el-form-item> -->
      <el-form-item prop="ownerOrganizations" label="Владение:">
        <remote-search-input
            v-model="secondEquipmentPanel.ownerOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :initial-value="secondEquipmentPanel.ownerOrganizationsInit"
            :show-when-init="true"
            :start-length=3
            :disabled="!editMode || secondEquipmentPanel.isAip"
          >
          </remote-search-input>
      </el-form-item>
      <el-form-item prop="technicalOrganizations" label="Технический учёт ДЦ:">
        <!-- <remote-search-input
            v-model="secondEquipmentPanel.technicalOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :initial-value="secondEquipmentPanel.technicalOrganizationsInit"
            :show-when-init="true"
            :start-length=3
            :disabled="!editMode || secondEquipmentPanel.isAip"
          >
          </remote-search-input> -->
        <el-select multiple filterable :collapse-tags="secondEquipmentPanel.technicalOrganizations.length > 5"
                    clearable v-model="secondEquipmentPanel.technicalOrganizations" size="small"
                    popper-class="search-select" :disabled="!editMode || secondEquipmentPanel.isAip">
          <div class="pl-3">
          </div>
          <el-option
            v-for="item in organizations"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="technologicalAdministrationOrganizations" label="Технологическое управление:">
        <!-- <remote-search-input
            v-model="secondEquipmentPanel.technologicalAdministrationOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :initial-value="secondEquipmentPanel.technologicalAdministrationOrganizationsInit"
            :show-when-init="true"
            :start-length=3
            :disabled="!editMode || secondEquipmentPanel.isAip"
          >
          </remote-search-input> -->
          <el-select multiple filterable  :disabled="!editMode || secondEquipmentPanel.isAip"
                      clearable v-model="secondEquipmentPanel.technologicalAdministrationOrganizations" size="small"
                      popper-class="search-select">
            <div class="pl-3">
                <el-button type="text" size="small"
                           @click="secondEquipmentPanel.technologicalAdministrationOrganizations = techAdministrationOrganizations.map((item) => item.id)">
                  Выбрать всё
                </el-button>
            </div>
            <el-option
              v-for="item in techAdministrationOrganizations"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item prop="technologicalManagementOrganizations" label="Технологическое ведение:">
        <!-- <remote-search-input
            v-model="secondEquipmentPanel.technologicalManagementOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :initial-value="secondEquipmentPanel.technologicalManagementOrganizationsInit"
            :show-when-init="true"
            :start-length=3
            :disabled="!editMode || secondEquipmentPanel.isAip"
          >
          </remote-search-input> -->
          <el-select multiple filterable  :disabled="!editMode || secondEquipmentPanel.isAip"
                      clearable v-model="secondEquipmentPanel.technologicalManagementOrganizations" size="small"
                      popper-class="search-select">
            <div class="pl-3">
                <el-button type="text" size="small"
                           @click="secondEquipmentPanel.technologicalManagementOrganizations = techManagementOrganizations.map((item) => item.id)">
                  Выбрать всё
                </el-button>
            </div>
            <el-option
              v-for="item in techManagementOrganizations"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item prop="dispatchAdministrationOrganizations" label="Диспетчерское управление:">
        <!-- <remote-search-input
            v-model="secondEquipmentPanel.dispatchAdministrationOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :initial-value="secondEquipmentPanel.dispatchAdministrationOrganizationsInit"
            :show-when-init="true"
            :start-length=3
            :disabled="!editMode || secondEquipmentPanel.isAip"
          >
          </remote-search-input> -->
          <el-select multiple filterable :disabled="!editMode || secondEquipmentPanel.isAip"
                      clearable v-model="secondEquipmentPanel.dispatchAdministrationOrganizations" size="small"
                      popper-class="search-select">
            <div class="pl-3">
                <el-button type="text" size="small"
                           @click="secondEquipmentPanel.dispatchAdministrationOrganizations = duOrganizations.map((item) => item.id)">
                  Выбрать всё
                </el-button>
            </div>
            <el-option
              v-for="item in duOrganizations"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item prop="dispatchManagementOrganizations" label="Диспетчерское ведение:">
        <!-- <remote-search-input
            v-model="secondEquipmentPanel.dispatchManagementOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :initial-value="secondEquipmentPanel.dispatchManagementOrganizationsInit"
            :show-when-init="true"
            :multiple="true"
            :start-length=3
            :disabled="!editMode || secondEquipmentPanel.isAip"
          >
          </remote-search-input> -->
          <el-select multiple filterable :disabled="!editMode || secondEquipmentPanel.isAip"
                      clearable v-model="secondEquipmentPanel.dispatchManagementOrganizations" size="small"
                      popper-class="search-select">
            <div class="pl-3">
                <el-button type="text" size="small"
                           @click="secondEquipmentPanel.dispatchManagementOrganizations = dvOrganizations.map((item) => item.id)">
                  Выбрать всё
                </el-button>
            </div>
            <el-option
              v-for="item in dvOrganizations"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item prop="idlePeriods" style="idle_periods" label="Периоды неработоспособности:" v-show="secondEquipmentPanel.idlePeriodsEditable">
        <el-row  class="mt-0">
          <el-table :data="secondEquipmentPanel.idlePeriods"
                    v-loading="loading"
                    size="small"
                    :default-sort = "{prop: 'code', order: 'ascending'}"
                    style="width: 100%">
            <el-table-column prop="startDate" label="Начало">
              <template slot-scope="props">
                  <el-date-picker
                    type="datetime"
                    v-model="props.row.startDate"
                    size="small"
                    v-mask="'##.##.#### ##:##'"
                    style="width: 100% !important;"
                    format="dd.MM.yyyy HH:mm"
                    :disabled="!(secondEquipmentPanel.idlePeriodsEditable && editMode)"
                    :picker-options="datePickerOptions"
                    @input="onIdlePeriodDateChange(props.row)">
                  </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column prop="stopDate" label="Конец">
              <template slot-scope="props">
                  <el-date-picker
                    type="datetime"
                    v-model="props.row.stopDate"
                    v-mask="'##.##.#### ##:##'"
                    style="width: 100% !important;"
                    size="small"
                    format="dd.MM.yyyy HH:mm"
                    :disabled="!(secondEquipmentPanel.idlePeriodsEditable && editMode)"
                    :picker-options="datePickerOptions"
                    @input="onIdlePeriodDateChange(props.row)">
                  </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column prop="total" label="Длительность">
              <template slot-scope="props">
                <el-input
                  v-model="props.row.total"
                  size="small"
                  disabled>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column v-if="(secondEquipmentPanel.idlePeriodsEditable && editMode)" width="42px">
              <template slot-scope="scope">
                <el-button @click="deleteRow(scope.$index)" type="text" icon="el-icon-delete"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="mt-1">
          <el-button
                      icon="el-icon-plus"
                      size="small"
                      class="p-1 mb-1 ml-2"
                      @click="addNewRow"
                      v-if="(secondEquipmentPanel.idlePeriodsEditable && editMode)">
                    Добавить период
          </el-button>
        </el-row>
      </el-form-item>
    </el-form>
    <energy-objects-modal
      v-if="energyObjectsModalVisible"
      v-model="energyObjectsModalVisible"
      :data="{id: this.secondEquipmentPanel.energyObjectId, name: this.secondEquipmentPanel.energyObjectName}"
      @on-update="energyObjectUpdate"
    />
  </el-dialog>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
import dateHelpers from '@/mixins/dateHelpers';
import secondEquipmentPanelsApi from '@/api/nsi/secondEquipmentPanels';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import SaveIcon from '@/assets/icons/save.svg';
import validationRules from '@/mixins/validationRules';
import energyObjectsModal from '@/views/Nsi/SecondEquipmentPanels/EnergyObjectsSelect/EnergyObjectsModal';
import regApi from '@/api/administration/userRegistration';
import organizationsApi from '@/api/nsi/organizations';

export default {
  name: 'SecondEquipmentPanelModal',
  props: ['value', 'secondEquipmentPanel'],
  mixins: [validationRules, dateHelpers],
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    ...mapGetters('dictionaries', ['registrationGroups', 'baseVoltages']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.editMode = false;
        this.$emit('input', val);
      },
    },
    panelRegistrationGroupName() {
      if (this.secondEquipmentPanel && this.secondEquipmentPanel.registrationGroupId) {
        const group = this.registrationGroups.find((gr) => gr.id === this.secondEquipmentPanel.registrationGroupId);
        if (group) return group.name;
      }

      return '';
    }
  },
  components: { RemoteSearchInput, SaveIcon, energyObjectsModal },
  data() {
    const idlePeriodsValidation = (rule, value, callback) => {
      const valid = this.validateIdlePeriods(this.secondEquipmentPanel.idlePeriods);
      if (valid) {
        return callback();
      } else {
        return callback(new Error('Все поля обязательны для заполнения и должны содержать корректные данные'));
      }
    };
    return {
      editMode: false,
      loading: false,
      energyObjectsModalVisible: false,
      techAdministrationOrganizations: [],
      techManagementOrganizations: [],
      dvOrganizations: [],
      duOrganizations: [],
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        productAssetModelId: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        energyObjectId: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        analysisIds: [
          { validator: this.isUUIDList, trigger: 'blur' }
        ],
        idlePeriods: [
          { validator: idlePeriodsValidation, message: 'Поля обязательны для заполнения и должны содержать корректные данные', trigger: 'blur' }
        ],
        mRid: [
          { validator: this.isUUID, trigger: 'blur' }
        ]
      },
      organizations: []
    };
  },
  async mounted() {
    this.$root.$on('addEvent', (x) => {
      this.editMode = x.editMode;
    });
    const org = await regApi.getDispatchCenters();
    if (org.data) {
      this.organizations = org.data;
    }
    const techAdmOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Технологическое управление');
    if (techAdmOrgs.data) {
      this.techAdministrationOrganizations = techAdmOrgs.data;
    }
    const techManOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Технологическое ведение');
    if (techManOrgs.data) {
      this.techManagementOrganizations = techManOrgs.data;
    }
    const dvOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Диспетчерское ведение');
    if (dvOrgs.data) {
      this.dvOrganizations = dvOrgs.data;
    }
    const duOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Диспетчерское управление');
    if (duOrgs.data) {
      this.duOrganizations = duOrgs.data;
    }
  },
  methods: {
    validateIdlePeriods(array) {
      let result = true;
      array.forEach((x) => {
        if (x.total === undefined || x.startDate === null || x.startDate === '' || x.stopDate === null || x.stopDate === '' || x.total === null || x.total === '' || x.startDate > x.stopDate) {
          result = false;
        }
      });
      return result;
    },
    handleClose() {
      this.dialogVisible = false;
      this.editMode = false;
    },
    async addSecondEquipmentPanel(secondEquipmentPanel) {
      await secondEquipmentPanelsApi.addSecondEquipmentPanel(secondEquipmentPanel);
      this.$emit('on-create');
    },
    async updateSecondEquipmentPanel(secondEquipmentPanel) {
      await secondEquipmentPanelsApi.updateSecondEquipmentPanel(secondEquipmentPanel);
      this.$emit('on-update');
    },
    addNewRow() {
      this.secondEquipmentPanel.idlePeriods.unshift({
        startDate: '',
        stopDate: '',
        total: ''
      });
    },
    deleteRow(idx) {
      this.$delete(this.secondEquipmentPanel.idlePeriods, idx);
    },
    onIdlePeriodDateChange(row) {
      if (row.startDate === '' || row.startDate === undefined || row.stopDate === '' || row.stopDate === undefined || row.stopDate < row.startDate) {
        row.total = '';
      } else {
        const startTime = new Date(row.startDate).getTime();
        const stopTime = new Date(row.stopDate).getTime();
        const hours = Math.floor((stopTime - startTime) / (3600 * 1000));
        const minutes = Math.floor(((stopTime - startTime) / (60 * 1000)) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
        row.total = `${hours}:${minutes}`;
      }
    },
    async validateForm() {
      const valid = await this.$refs.secondEquipmentPanelForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;

      const result = cloneDeep(this.secondEquipmentPanel);
      result.idlePeriods.forEach((element) => {
        element.startDate = this.dateSetter(element.startDate);
        element.stopDate = this.dateSetter(element.stopDate);
      });
      const request = this.secondEquipmentPanel.id ? this.updateSecondEquipmentPanel : this.addSecondEquipmentPanel;
      this.loading = true;
      await request(result);
      this.editMode = false;
      this.dialogVisible = false;
      this.loading = false;
    },
    handleEdit() {
      this.editMode = true;
    },
    handleDelete(id) {
        this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
          confirmButtonText: 'ОК',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }).then(async () => {
          this.loading = true;
          const res = await secondEquipmentPanelsApi.deleteSecondEquipmentPanel(id);
          if (!res.data.succeeded) {
            this.$alert(res.data.resultMessage, 'Ошибка', {
              confirmButtonText: 'OK',
              type: 'warning',
            });
          } else {
            this.$emit('on-delete');
            this.dialogVisible = false;
          }
          this.loading = false;
        });
      },
    handleEnergyObjectsSelect() {
      this.energyObjectsModalVisible = true;
    },
    energyObjectUpdate(eo) {
      if (eo) {
        this.secondEquipmentPanel.energyObjectId = eo.id;
        this.secondEquipmentPanel.energyObjectName = eo.name;
      } else {
        this.secondEquipmentPanel.energyObjectId = null;
        this.secondEquipmentPanel.energyObjectName = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.idle_periods {
  .el-form-item__content {
      flex-direction: column;
      max-width: 30%;
  }
}
</style>
